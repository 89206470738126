import React from "react"
import { navigate } from 'gatsby'

const IndexPage = () => {
  React.useEffect(() => {
    navigate('/cv/1')
  }, [])

  return (
      <div></div>
  )
}

export default IndexPage
